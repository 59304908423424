@import 'base.scss';

.tooltip {
	// !important needed, see
	// https://reacttooltip.github.io/react-tooltip/
	min-width: 140px;
	max-width: 250px;
	border-radius: 5px !important;
	font-size: 12px !important;
	padding: 12px 16px !important;
	text-align: center;
}
