@import 'base.scss';

.advanced-label {
	background-color: $orange-95;
	border-radius: 4px;
	display: grid;
	flex-shrink: 0;
	height: 20px;
	place-items: center;
	width: 20px;

	&__icon {
		color: $orange-50;
	}
}
