@import 'base.scss';

.header {
	align-items: center;
	border-bottom: 1px solid $grey-90;
	padding: 0 20px;
	display: flex;
	height: $overlay-header-height;
	justify-content: space-between;
	gap: 16px;

	&__content-wrapper {
		display: flex;
		align-items: center;
		gap: 16px;
		justify-content: space-between;
		height: 100%;
		width: 100%;
	}

	&__icon {
		align-items: center;
		cursor: pointer;
		display: flex;
	}

	&--without-close-button {
		margin-right: 0;
	}
}

.content {
	display: flex;
	align-items: center;
	height: 100%;

	&__title {
		color: $grey-10;
		cursor: default;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		white-space: nowrap;
	}

	&__tab:last-of-type {
		margin-right: 24px;
	}
}

.icon {
	color: $grey-10;
}
